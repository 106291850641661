import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';
import {ReactComponent as SendIcon} from './icons/send_fill1_24px.svg';
import {ReactComponent as LanguageIcon} from './icons/language_fill1_20px.svg';
import {useNavigate} from "react-router-dom";
import { ReactComponent as MenuIcon } from './icons/menu_fill1_24px.svg';
import YouTubeEmbed from "./YoutubeEmbedded";

const StoryTextArea = styled.textarea`
    position: absolute;
    bottom: 10px;
    flex-grow: 1;
    padding: 7px;
    border-radius: 10px;
    width: 80%;
    height: 45px;
    border: #f2efff;

    @media (max-width: 800px) {
        width: 95%;
    }
`;

interface FooterContainerProps {
    disabled?: boolean; // Propriété disabled optionnelle de type boolean
}
const FooterContainer = styled.div<FooterContainerProps>`
    background-color: #f2efff; 
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    position: fixed;
    bottom: 0px;
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 6px;
    height: 90px;
`;

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

const AccordionContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    margin-left: 10px;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LiContainer = styled.li`
    margin-bottom: 10px;
`;

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const Home = () => {
    const [text, setText] = useState<string>("");


    const { t } = useTranslation();

    const initialLanguage = 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);

    const lang = localStorage.getItem('language');
    const isRtl = lang === 'ar';

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        Prism.highlightAll();
        if (token) {
            navigate('/conversation');
        }
    }, []);

    return (
        <div>
            <MainContainer>

                    {t('videoTutoId') && t('videoTutoId') != 'videoTutoId' ? (
                        <AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                            <VideoContainer>
                            <YouTubeEmbed videoId={t('videoTutoId')} />
                             </VideoContainer>
                        </AccordionContainer>) :
                        (<AccordionContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                            <p>
                                <Trans i18nKey="menuLabel" components={[<MenuIcon />]} />
                            </p>
                            <ul>
                                <LiContainer>+ {t('conversation')} : {t('allowGenerateConversation')}</LiContainer>
                                <LiContainer>+ {t('story')} : {t('allowGenerateStory')}</LiContainer>
                                <LiContainer>{t('conversations')} : {t('displayMyConversations')}</LiContainer>
                                <LiContainer>{t('myStories')} : {t('displayMyStories')}</LiContainer>
                                <LiContainer>{t('languages')} : {t('choiceMyLanguage')}</LiContainer>
                            </ul>
                        </AccordionContainer>)
                    }

            </MainContainer>
            <FooterContainer disabled={true}>
                <StoryTextArea value={text} onChange={e => setText(e.target.value)}>
                </StoryTextArea>
                <SendIcon style={{position: 'absolute', right: '3px', bottom: '10px'}} >{t('send')}</SendIcon>

            </FooterContainer>
        </div>
    );
};

export default Home;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const resources = {
    en: {
        translation: {
            "playAll": "Play All",
            "conversationIn": "Conversation in",
            "conversation": "Conversation",
            "send": "Send",
            "comingSoon": "Coming soon",
            "conversations": "My Conversations",
            "noDownloadsAvailable": "No downloads available",

            "story": "Story",
            "storyIn": "Story in",
            "myStories": "My stories",
            "pauseAll": "Pause",
            "speed": "Speed",
            "speed_very_slow": "Very slow",
            "speed_slow": "Slow",
            "speed_normal": "Normal",
            "speed_fast": "Fast",
            "speed_very_fast": "Very fast",
            "level": "Level",
            "level_easy": "Easy",
            "level_intermediate": "Intermediate",
            "level_advanced": "Advanced",

            "start": "Click here to start",

            "myAccount": "My account",
            "signOut": "Sign out",
            "signInWithGoogle": "Sign in",
            "title": "Title:",

            "menuLabel": "Once connected, the <0></0> menu is very simple",
            "allowGenerateConversation": "allows generating a conversation",
            "allowGenerateStory": "allows generating a story",
            "displayMyConversations": "display my previously generated conversations",
            "displayMyStories": "display my previously generated stories",
            "choiceMyLanguage": "choice of my language",
            "HMethod": "The H Method revolutionizes language learning by helping you progress quickly",
            "listenOnly": "Listen without looking at the text",
            "listenAndRead": "Listen while reading the text",
            "read": "Read the text out loud",
            "understand": "Try to understand, if necessary (with translation)",

            "conversationPlaceholder": "Examples: Tourist and taxi driver in Miami, Job interview ...",
            "storyPlaceholder": "Examples: Trip to the Amazon, Humanity fighting for survival, Monument visit ...",

            "english":"English",
            "spanish":"Spanish",
            "arabic":"Arabic",
            "french":"French",
            "german":"German",
            "chinese":"Chinese",
            "japanese":"Japanese",
            "hindi":"Hindi",
            "turkish":"Turkish",
            "korean":"Korean",
            "italian":"Italian",
            "dutch":"Dutch",
            "russian":"Russian",
            "indonesian":"Indonesian",

            "videoTutoId": "T5RCcvGuThI"
        }
    },
    es: {
        translation: {
            "playAll": "Reproducir todo",
            "conversationIn": "Diálogo en",
            "conversation": "Diálogo",
            "send": "Enviar",
            "comingSoon": "Próximamente disponible",
            "conversations": "Mis Diálogos",

            "story": "Historia",
            "storyIn": "Historia en",
            "myStories": "Mis historias",
            "pauseAll": "Pausa",
            "speed": "Velocidad",
            "speed_very_slow": "Muy lento",
            "speed_slow": "Lento",
            "speed_normal": "Normal",
            "speed_fast": "Rápido",
            "speed_very_fast": "Muy rápido",
            "level": "Nivel",
            "level_easy": "Fácil",
            "level_intermediate": "Intermedio",
            "level_advanced": "Avanzado",
            "noDownloadsAvailable": "No hay descargas disponibles",
            "start": "Haz clic aquí para comenzar",
            "myAccount": "Mi cuenta",
            "signOut": "Cerrar sesión",
            "signInWithGoogle": "Iniciar sesión",
            "title": "Título:",

            "menuLabel": "Una vez conectado, el menú <0></0> es muy simple",
            "allowGenerateConversation": "permite generar una conversación",
            "allowGenerateStory": "permite generar una historia",
            "displayMyConversations": "mostrar mis conversaciones ya generadas",
            "displayMyStories": "mostrar mis historias ya generadas",
            "choiceMyLanguage": "elección de mi idioma",
            "HMethod": "El Método H revoluciona el aprendizaje de idiomas al ayudarte a progresar rápidamente",
            "listenOnly": "Escuchar sin mirar el texto",
            "listenAndRead": "Escuchar mientras lees el texto",
            "read": "Leer el texto en voz alta",
            "understand": "Intentar entender, si es necesario (con traducción)",

            "conversationPlaceholder": "Ejemplos: Turista y taxista en Miami, Entrevista de trabajo ...",
            "storyPlaceholder": "Ejemplos: Viaje a la Amazonía, La humanidad lucha por su supervivencia, Visita al monumento ...",

            "english":"Inglés",
            "spanish":"Español",
            "arabic":"Árabe",
            "french":"Francés",
            "german":"Alemán",
            "chinese":"Chino",
            "japanese":"Japonés",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Holandés",
            "russian":"Ruso",
            "indonesian":"Indonesio",

            "videoTutoId": "FEfjMGZq9sM"
        }
    },
    ar: {
        translation: {
            "playAll": "تشغيل الكل",
            "conversationIn": "الحوار ب",
            "conversation": "حوار",
            "send": "إرسال",
            "comingSoon": "قريباً",
            "conversations": "حواراتي",
            "story": "قصة",
            "storyIn": "قصة ب",
            "myStories": "قصصي",
            "pauseAll": "إيقاف",
            "speed": "السرعة",
            "speed_very_slow": "بطيء جدًا",
            "speed_slow": "بطيء",
            "speed_normal": "عادي",
            "speed_fast": "سريع",
            "speed_very_fast": "سريع جدًا",
            "level": "المستوى",
            "level_easy": "سهل",
            "level_intermediate": "متوسط",
            "level_advanced": "متقدم",
            "noDownloadsAvailable": "لا توجد تنزيلات متاحة",
            "start": "اضغط هنا للبدء",
            "myAccount": "حسابي",
            "signOut": "تسجيل الخروج",
            "signInWithGoogle": "تسجيل الدخول",
            "title": "العنوان:",

            "menuLabel": "بمجرد تسجيل الدخول، يصبح <0></0> القائمة بسيطة للغاية",
            "allowGenerateConversation": "يسمح بإنشاء حوار",
            "allowGenerateStory": "يسمح بإنشاء قصة",
            "displayMyConversations": "عرض حواراتي التي تم إنشاؤها مسبقاً",
            "displayMyStories": "عرض قصصي التي تم إنشاؤها مسبقاً",
            "choiceMyLanguage": "اختيار لغتي",
            "HMethod": "طريقة H تُحدث ثورة في تعلم اللغات من خلال مساعدتك على التقدم بسرعة",
            "listenOnly": "الاستماع بدون النظر إلى النص",
            "listenAndRead": "الاستماع أثناء قراءة النص",
            "read": "قراءة النص بصوت عالٍ",
            "understand": "محاولة الفهم، إذا لزم الأمر (مع الترجمة)",

            "conversationPlaceholder": "أمثلة: سائح وسائق سيارة أجرة في ميامي، مقابلة عمل ...",
            "storyPlaceholder": "أمثلة: رحلة إلى الأمازون، البشرية تكافح من أجل البقاء، زيارة النصب التذكاري ...",

            "english": "إنجليزية",
            "spanish": "إسبانية",
            "arabic": "عربى",
            "french": "فرنسي",
            "german": "ألمانية",
            "chinese": "صينى",
            "japanese": "يابانية",
            "hindi": "هندي",
            "turkish": "تركي",
            "korean": "كوري",
            "italian": "إيطالي",
            "dutch": "هولندي",
            "russian": "روسي",
            "indonesian": "إندونيسي",
            "videoTutoId": "V8-qRZG24sI"
        }
    },
    de: {
        translation: {
            "playAll": "Alles abspielen",
            "conversationIn": "Gespräch in",

            "conversation": "Gespräch",

            "send": "Senden",
            "comingSoon": "Demnächst verfügbar",

            "conversations": "Meine Dialoge",

            "story": "Geschichte",
            "storyIn": "Geschichte in",
            "myStories": "Meine Geschichten",
            "pauseAll": "Pause",
            "speed": "Geschwindigkeit",
            "speed_very_slow": "Sehr langsam",
            "speed_slow": "Langsam",
            "speed_normal": "Normal",
            "speed_fast": "Schnell",
            "speed_very_fast": "Sehr schnell",
            "level": "Niveau",
            "level_easy": "Einfach",
            "level_intermediate": "Mittel",
            "level_advanced": "Fortgeschritten",
            "noDownloadsAvailable": "Keine Downloads verfügbar",
            "myAccount": "Mein Konto",
            "signOut": "Abmelden",
            "signInWithGoogle": "Anmelden",
            "title": "Titel:",

            "menuLabel": "Nach dem Einloggen ist das Menü <0></0> sehr einfach",
            "allowGenerateConversation": "ermöglicht das Erstellen eines Gesprächs",
            "allowGenerateStory": "ermöglicht das Erstellen einer Geschichte",
            "displayMyConversations": "meine bereits erstellten Gespräche anzeigen",
            "displayMyStories": "meine bereits erstellten Geschichten anzeigen",
            "choiceMyLanguage": "meine Sprachwahl",
            "HMethod": "Die H-Methode revolutioniert das Sprachenlernen, indem sie Ihnen hilft, schnell Fortschritte zu machen",
            "listenOnly": "Nur zuhören, ohne den Text zu lesen",
            "listenAndRead": "Beim Zuhören den Text lesen",
            "read": "Den Text laut vorlesen",
            "understand": "Versuchen zu verstehen, bei Bedarf (mit Übersetzung)",

            "conversationPlaceholder": "Beispiele: Tourist und Taxifahrer in Miami, Vorstellungsgespräch ...",
            "storyPlaceholder": "Beispiele: Reise in den Amazonas, Die Menschheit kämpft ums Überleben, Besuch des Denkmals ...",

            "english":"Englisch",
            "spanish":"Spanisch",
            "arabic":"Arabisch",
            "french":"Französisch",
            "german":"Deutsch",
            "chinese":"Chinesisch",
            "japanese":"Japanisch",
            "hindi":"Hindi",
            "turkish":"Türkisch",
            "korean":"Koreanisch",
            "italian":"Italienisch",
            "dutch":"Niederländisch",
            "russian":"Russisch",
            "indonesian":"Indonesisch",

            "videoTutoId": "55nomi9QMQw"
        }
    },
    fr: {
        translation: {
            "playAll": "Tout lire",
            "conversationIn": "Dialogue en",
            "conversation": "Dialogue",
            "comingSoon": "Bientôt disponible",
            "send": "Envoyer",

            "conversations": "Mes dialogues",
            "noDownloadsAvailable": "Aucun téléchargement disponible",
            "story": "Histoire",
            "storyIn": "Histoire en",
            "myStories": "Mes histoires",
            "pauseAll": "Pause",
            "speed": "Vitesse",
            "speed_very_slow": "Très lent",
            "speed_slow": "Lent",
            "speed_normal": "Normal",
            "speed_fast": "Rapide",
            "speed_very_fast": "Très rapide",
            "level": "Niveau",
            "level_easy": "Facile",
            "level_intermediate": "Intermédiaire",
            "level_advanced": "Avancé",

            "myAccount": "Mon compte",
            "signOut": "Se déconnecter",
            "signInWithGoogle": "Se connecter",
            "featureNotYetAvailable": "Fonctionnalité pas encore disponible",
            "title": "Titre:",

            "menuLabel": "Une fois connecté, le menu <0></0> est très simple ",
            "allowGenerateConversation": "permet de générer un dialogue",
            "allowGenerateStory": "permet de générer une histoire",
            "displayMyConversations": "afficher mes dialogues déjà générés",
            "displayMyStories": "afficher mes histoires déjà générées",
            "choiceMyLanguage": "choix de ma langue",
            "HMethod": "La méthode H révolutionne l'apprentissage des langues en vous permettant de progresser rapidement",
            "listenOnly": "Écouter sans regarder le texte",
            "listenAndRead": "Écouter en lisant le texte",
            "read": "Lire le texte à voix haute",
            "understand": "Essayer de comprendre, si nécessaire (avec traduction)",

            "conversationPlaceholder": "Exemples : Touriste et chauffeur de taxi à Miami, Entretien d'embauche ...",
            "storyPlaceholder": "Exemples : Voyage en amazonie, L'humanité lutte pour sa survie, Visite du monument ... ",

            "english":"Anglais",
            "spanish":"Espagnol",
            "arabic":"Arabe",
            "french":"Français",
            "german":"Allemand",
            "chinese":"Chinois",
            "japanese":"Japonais",
            "hindi":"Hindi",
            "turkish":"Turc",
            "korean":"Coréen",
            "italian":"Italien",
            "dutch":"Néerlandais",
            "russian":"Russe",
            "indonesian":"Indonésien",

            "videoTutoId": "KGc0mDAoy_g"
        }
    },
    zh: {
        translation: {
            "playAll": "播放全部",
            "conversationIn": "对话在",

            "conversation": "对话",

            "send": "发送",
            "comingSoon": "即将推出",

            "conversations": "我的对话",

            "story": "故事",
            "storyIn": "故事在",
            "myStories": "我的故事",
            "pauseAll": "暂停",
            "speed": "速度",
            "speed_very_slow": "非常慢",
            "speed_slow": "慢",
            "speed_normal": "正常",
            "speed_fast": "快",
            "speed_very_fast": "非常快",
            "level": "水平",
            "level_easy": "简单",
            "level_intermediate": "中等",
            "level_advanced": "高级",
            "noDownloadsAvailable": "无可用下载",
            "start": "点击这里开始",

            "myAccount": "我的账户",
            "signOut": "登出",
            "signInWithGoogle": "登录",
            "title": "标题:",

            "menuLabel": "登录后，<0></0> 菜单非常简单",
            "allowGenerateConversation": "允许生成对话",
            "allowGenerateStory": "允许生成故事",
            "displayMyConversations": "显示我已生成的对话",
            "displayMyStories": "显示我已生成的故事",
            "choiceMyLanguage": "选择我的语言",
            "HMethod": "H方法通过帮助您快速进步来革新语言学习",
            "listenOnly": "仅听而不看文本",
            "listenAndRead": "边听边看文本",
            "read": "大声朗读文本",
            "understand": "尝试理解，如有需要（带翻译）",

            "conversationPlaceholder": "示例：游客和迈阿密的出租车司机，工作面试 ...",
            "storyPlaceholder": "示例：亚马逊之旅，人类为生存而战，参观纪念碑 ...",

            "english":"英语",
            "spanish":"西班牙语",
            "arabic":"阿拉伯语",
            "french":"法语",
            "german":"德语",
            "chinese":"中文",
            "japanese":"日语",
            "hindi":"印地语",
            "turkish":"土耳其语",
            "korean":"韩语",
            "italian":"意大利语",
            "dutch":"荷兰语",
            "russian":"俄语",
            "indonesian":"印度尼西亚语",
            "videoTutoId": "kEtLWsxNXDc"
        }
    },
    hi: {
        translation: {
            "playAll": "सभी चलाएं",
            "conversationIn": "संवाद में",

            "conversation": "संवाद",

            "send": "भेजें",
            "comingSoon": "जल्द ही उपलब्ध होगा",

            "conversations": "मेरी बातचीतें",

            "story": "कहानी",
            "storyIn": "कहानी में",
            "myStories": "मेरी कहानियाँ",
            "pauseAll": "रोकें",
            "speed": "गति",
            "speed_very_slow": "बहुत धीमी",
            "speed_slow": "धीमी",
            "speed_normal": "सामान्य",
            "speed_fast": "तेज़",
            "speed_very_fast": "बहुत तेज़",
            "level": "स्तर",
            "level_easy": "आसान",
            "level_intermediate": "मध्यम",
            "level_advanced": "उन्नत",
            "noDownloadsAvailable": "कोई डाउनलोड उपलब्ध नहीं है",
            "start": "शुरू करने के लिए यहां क्लिक करें",

            "myAccount": "मेरा खाता",
            "signOut": "साइन आउट",
            "signInWithGoogle": "लॉग इन करें",
            "title": "शीर्षक:",

            "menuLabel": "लॉगिन करने के बाद, <0></0> मेनू बहुत सरल है",
            "allowGenerateConversation": "वार्ता उत्पन्न करने की अनुमति देता है",
            "allowGenerateStory": "कहानी उत्पन्न करने की अनुमति देता है",
            "displayMyConversations": "मेरी पहले से उत्पन्न वार्ताओं को दिखाएं",
            "displayMyStories": "मेरी पहले से उत्पन्न कहानियों को दिखाएं",
            "choiceMyLanguage": "मेरी भाषा का चयन",
            "HMethod": "H विधि भाषा सीखने में क्रांति लाती है और आपको तेजी से प्रगति करने में मदद करती है",
            "listenOnly": "पाठ को देखे बिना केवल सुनें",
            "listenAndRead": "पाठ पढ़ते हुए सुनें",
            "read": "पाठ को जोर से पढ़ें",
            "understand": "समझने का प्रयास करें, यदि आवश्यक हो (अनुवाद के साथ)",

            "conversationPlaceholder": "उदाहरण: मियामी में पर्यटक और टैक्सी चालक, नौकरी का साक्षात्कार ...",
            "storyPlaceholder": "उदाहरण: अमेज़न यात्रा, मानवता के अस्तित्व के लिए संघर्ष, स्मारक का दौरा ...",

            "english":"अंग्रेज़ी",
            "spanish":"स्पेनिश",
            "arabic":"अरबी",
            "french":"फ़्रेंच",
            "german":"जर्मन",
            "chinese":"चीनी",
            "japanese":"जापानी",
            "hindi":"हिंदी",
            "turkish":"तुर्की",
            "korean":"कोरियाई",
            "italian":"इटालियन",
            "dutch":"डच",
            "russian":"रूसी",
            "indonesian":"इंडोनेशियाई",

            "videoTutoId": "WQ66D_rDUiw"

        }
    },
    ja: {
        translation: {
            "playAll": "すべて再生",
            "conversationIn": "会話中",
            "conversation": "会話",

            "send": "送信",
            "comingSoon": "近日公開",

            "conversations": "私の対話",

            "story": "ストーリー",
            "storyIn": "ストーリーで",
            "myStories": "私のストーリー",
            "pauseAll": "一時停止",
            "speed": "速度",
            "speed_very_slow": "非常に遅い",
            "speed_slow": "遅い",
            "speed_normal": "普通",
            "speed_fast": "速い",
            "speed_very_fast": "非常に速い",
            "level": "レベル",
            "level_easy": "簡単",
            "level_intermediate": "中級",
            "level_advanced": "上級",
            "noDownloadsAvailable": "ダウンロードはありません",
            "start": "ここをクリックして開始してください",
            "myAccount": "マイアカウント",
            "signOut": "サインアウト",
            "signInWithGoogle": "ログイン",
            "title": "タイトル:",

            "menuLabel": "ログイン後、<0></0> メニューはとてもシンプルです",
            "allowGenerateConversation": "会話を生成することができます",
            "allowGenerateStory": "物語を生成することができます",
            "displayMyConversations": "生成済みの会話を表示する",
            "displayMyStories": "生成済みの物語を表示する",
            "choiceMyLanguage": "言語の選択",
            "HMethod": "Hメソッドは、速やかに進歩できるようサポートし、言語学習を革命的に変えます",
            "listenOnly": "テキストを見ずに聞く",
            "listenAndRead": "テキストを読みながら聞く",
            "read": "テキストを声に出して読む",
            "understand": "必要に応じて（翻訳付きで）理解を試みる",

            "conversationPlaceholder": "例：マイアミの観光客とタクシー運転手、面接 ...",
            "storyPlaceholder": "例：アマゾンへの旅、人類の生存戦争、記念碑訪問 ...",

            "english":"英語",
            "spanish":"スペイン語",
            "arabic":"アラビア語",
            "french":"フランス語",
            "german":"ドイツ語",
            "chinese":"中国語",
            "japanese":"日本語",
            "hindi":"ヒンディー語",
            "turkish":"トルコ語",
            "korean":"韓国語",
            "italian":"イタリア語",
            "dutch":"オランダ語",
            "russian":"ロシア語",
            "indonesian":"インドネシア語"


        }
    },
    it: {
        translation: {
            "playAll": "Riproduci tutto",
            "conversationIn": "Conversazione in",
            "conversation": "Conversazione",

            "send": "Invia",
            "comingSoon": "Prossimamente disponibile",

            "conversations": "Le mie conversazioni",

            "story": "Storia",
            "storyIn": "Storia in",
            "myStories": "Le mie storie",
            "pauseAll": "Pausa",
            "speed": "Velocità",
            "speed_very_slow": "Molto lento",
            "speed_slow": "Lento",
            "speed_normal": "Normale",
            "speed_fast": "Veloce",
            "speed_very_fast": "Molto veloce",
            "level": "Livello",
            "level_easy": "Facile",
            "level_intermediate": "Intermedio",
            "level_advanced": "Avanzato",
            "noDownloadsAvailable": "Nessun download disponibile",
            "start": "Clicca qui per iniziare",
            "myAccount": "Il mio account",
            "signOut": "Esci",
            "signInWithGoogle": "Accedi",
            "title": "Titolo:",

            "menuLabel": "Una volta effettuato l'accesso, il menu <0></0> è molto semplice",
            "allowGenerateConversation": "permette di generare una conversazione",
            "allowGenerateStory": "permette di generare una storia",
            "displayMyConversations": "visualizza le mie conversazioni già generate",
            "displayMyStories": "visualizza le mie storie già generate",
            "choiceMyLanguage": "scelta della mia lingua",
            "HMethod": "Il metodo H rivoluziona l'apprendimento delle lingue aiutandoti a progredire rapidamente",
            "listenOnly": "Ascolta senza guardare il testo",
            "listenAndRead": "Ascolta mentre leggi il testo",
            "read": "Leggi il testo ad alta voce",
            "understand": "Cerca di capire, se necessario (con traduzione)",

            "conversationPlaceholder": "Esempi: Turista e tassista a Miami, Colloquio di lavoro ...",
            "storyPlaceholder": "Esempi: Viaggio in Amazzonia, L'umanità lotta per la sopravvivenza, Visita al monumento ...",

            "english":"Inglese",
            "spanish":"Spagnolo",
            "arabic":"Arabo",
            "french":"Francese",
            "german":"Tedesco",
            "chinese":"Cinese",
            "japanese":"Giapponese",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Olandese",
            "russian":"Russo",
            "indonesian":"Indonesiano",

        }
    },
    ko: {
        translation: {
            "playAll": "모두 재생",
            "conversationIn": "대화 중",
            "conversation": "대화",
            "send": "보내기",
            "comingSoon": "곧 출시 예정",
            "conversations": "내 대화",
            "story": "이야기",
            "storyIn": "이야기에서",
            "myStories": "내 이야기",
            "pauseAll": "일시 정지",
            "speed": "속도",
            "speed_very_slow": "매우 느림",
            "speed_slow": "느림",
            "speed_normal": "보통",
            "speed_fast": "빠름",
            "speed_very_fast": "매우 빠름",
            "level": "수준",
            "level_easy": "쉬움",
            "level_intermediate": "중간",
            "level_advanced": "고급",
            "noDownloadsAvailable": "다운로드 없음",
            "start": "시작하려면 여기를 클릭하세요",
            "myAccount": "내 계정",
            "signOut": "로그 아웃",
            "signInWithGoogle": "로그인",
            "title": "제목:",

            "menuLabel": "로그인 후 <0></0> 메뉴는 매우 간단합니다",
            "allowGenerateConversation": "대화를 생성할 수 있습니다",
            "allowGenerateStory": "이야기를 생성할 수 있습니다",
            "displayMyConversations": "이미 생성된 대화를 표시",
            "displayMyStories": "이미 생성된 이야기를 표시",
            "choiceMyLanguage": "언어 선택",
            "HMethod": "H 방법은 빠르게 진보할 수 있도록 도와주며 언어 학습을 혁신합니다",
            "listenOnly": "텍스트를 보지 않고 듣기",
            "listenAndRead": "텍스트를 읽으면서 듣기",
            "read": "텍스트를 큰 소리로 읽기",
            "understand": "필요한 경우 (번역과 함께) 이해하려고 노력하기",

            "conversationPlaceholder": "예시: 마이애미에서 관광객과 택시 운전사, 면접 ...",
            "storyPlaceholder": "예시: 아마존 여행, 생존을 위한 인류의 투쟁, 기념물 방문 ...",

            "english":"영어",
            "spanish":"스페인어",
            "arabic":"아라비아어",
            "french":"프랑스어",
            "german":"독일어",
            "chinese":"중국어",
            "japanese":"일본어",
            "hindi":"힌디어",
            "turkish":"터키어",
            "korean":"한국어",
            "italian":"이탈리아어",
            "dutch":"네덜란드어",
            "russian":"러시아어",
            "indonesian":"인도네시아어"


        }
    },
    nl: {
        translation: {
            "playAll": "Alles afspelen",
            "conversationIn": "Gesprek in",
            "conversation": "Gesprek",

            "send": "Verzenden",
            "comingSoon": "Binnenkort beschikbaar",

            "conversations": "Mijn gesprekken",

            "story": "Verhaal",
            "storyIn": "Verhaal in",
            "myStories": "Mijn verhalen",
            "pauseAll": "Pauze",
            "speed": "Snelheid",
            "speed_very_slow": "Zeer langzaam",
            "speed_slow": "Langzaam",
            "speed_normal": "Normaal",
            "speed_fast": "Snel",
            "speed_very_fast": "Zeer snel",
            "level": "Niveau",
            "level_easy": "Gemakkelijk",
            "level_intermediate": "Gemiddeld",
            "level_advanced": "Geavanceerd",
            "noDownloadsAvailable": "Geen downloads beschikbaar",
            "start": "Klik hier om te beginnen",
            "myAccount": "Mijn account",
            "signOut": "Uitloggen",
            "signInWithGoogle": "Inloggen",
            "title": "Titel:",

            "menuLabel": "Na inloggen is het <0></0> menu zeer eenvoudig",
            "allowGenerateConversation": "staat toe om een gesprek te genereren",
            "allowGenerateStory": "staat toe om een verhaal te genereren",
            "displayMyConversations": "toon mijn reeds gegenereerde gesprekken",
            "displayMyStories": "toon mijn reeds gegenereerde verhalen",
            "choiceMyLanguage": "keuze van mijn taal",
            "HMethod": "De H-methode revolutioneert taalverwerving door je snel te laten vooruitgaan",
            "listenOnly": "Luister zonder naar de tekst te kijken",
            "listenAndRead": "Luister terwijl je de tekst leest",
            "read": "Lees de tekst hardop",
            "understand": "Probeer te begrijpen, indien nodig (met vertaling)",

            "conversationPlaceholder": "Voorbeelden: Toerist en taxichauffeur in Miami, Sollicitatiegesprek ...",
            "storyPlaceholder": "Voorbeelden: Reis naar de Amazone, Mensheid vecht voor zijn voortbestaan, Bezoek aan het monument ...",

            "english":"Engels",
            "spanish":"Spaans",
            "arabic":"Arabisch",
            "french":"Frans",
            "german":"Duits",
            "chinese":"Chinees",
            "japanese":"Japans",
            "hindi":"Hindi",
            "turkish":"Turks",
            "korean":"Koreaans",
            "italian":"Italiaans",
            "dutch":"Nederlands",
            "russian":"Russisch",
            "indonesian":"Indonesisch"


        }
    },
    tr: {
        translation: {
            "playAll": "Hepsini oynat",
            "conversationIn": "Konuşma",
            "conversation": "Konuşma",

            "send": "Gönder",
            "comingSoon": "Yakında",

            "conversations": "Sohbetlerim",

            "story": "Hikaye",
            "storyIn": "Hikaye içinde",
            "myStories": "Hikayelerim",
            "pauseAll": "Duraklat",
            "speed": "Hız",
            "speed_very_slow": "Çok yavaş",
            "speed_slow": "Yavaş",
            "speed_normal": "Normal",
            "speed_fast": "Hızlı",
            "speed_very_fast": "Çok hızlı",
            "level": "Seviye",
            "level_easy": "Kolay",
            "level_intermediate": "Orta",
            "level_advanced": "İleri",
            "noDownloadsAvailable": "İndirme yok",
            "start": "Başlamak için buraya tıklayın",

            "myAccount": "Hesabım",
            "signOut": "Çıkış Yap",
            "signInWithGoogle": "Giriş yap",
            "title": "Başlık:",

            "menuLabel": "Giriş yaptıktan sonra, <0></0> menüsü çok basit",
            "allowGenerateConversation": "bir diyalog oluşturulmasına izin verir",
            "allowGenerateStory": "bir hikaye oluşturulmasına izin verir",
            "displayMyConversations": "önceden oluşturulmuş diyaloglarımı göster",
            "displayMyStories": "önceden oluşturulmuş hikayelerimi göster",
            "choiceMyLanguage": "dilimi seçme",
            "HMethod": "H Yöntemi, dil öğrenimini devrim niteliğinde değiştirerek hızlı bir şekilde ilerlemenizi sağlar",
            "listenOnly": "metne bakmadan dinleyin",
            "listenAndRead": "metni okurken dinleyin",
            "read": "metni yüksek sesle okuyun",
            "understand": "gerekirse (çeviri ile) anlamaya çalışın",

            "conversationPlaceholder": "Örnekler: Miami'de turist ve taksi şoförü, İş görüşmesi ...",
            "storyPlaceholder": "Örnekler: Amazon'a seyahat, İnsanlık hayatta kalma mücadelesi, Anıta ziyaret ...",

            "english":"İngilizce",
            "spanish":"İspanyolca",
            "arabic":"Arapça",
            "french":"Fransızca",
            "german":"Almanca",
            "chinese":"Çince",
            "japanese":"Japonca",
            "hindi":"Hintçe",
            "turkish":"Türkçe",
            "korean":"Korece",
            "italian":"İtalyanca",
            "dutch":"Hollandaca",
            "russian":"Rusça",
            "indonesian":"Endonezyaca"


        }
    },
    ru: {
        translation: {
            "playAll": "Воспроизвести все",
            "conversationIn": "Диалог в",
            "conversation": "Диалог",

            "send": "Отправить",
            "comingSoon": "Скоро будет доступно",

            "conversations": "Мои диалоги",

            "story": "История",
            "storyIn": "История в",
            "myStories": "Мои истории",
            "pauseAll": "Пауза",
            "speed": "Скорость",
            "speed_very_slow": "Очень медленно",
            "speed_slow": "Медленно",
            "speed_normal": "Нормально",
            "speed_fast": "Быстро",
            "speed_very_fast": "Очень быстро",
            "level": "Уровень",
            "level_easy": "Легкий",
            "level_intermediate": "Средний",
            "level_advanced": "Продвинутый",
            "noDownloadsAvailable": "Нет доступных загрузок",
            "start": "Нажмите здесь, чтобы начать",

            "myAccount": "Мой аккаунт",
            "signOut": "Выйти",
            "signInWithGoogle": "Войти",
            "title": "Название:",

            "menuLabel": "После входа в систему меню <0></0> очень простое",
            "allowGenerateConversation": "разрешает генерировать разговор",
            "allowGenerateStory": "разрешает генерировать историю",
            "displayMyConversations": "отобразить мои уже сгенерированные разговоры",
            "displayMyStories": "отобразить мои уже сгенерированные истории",
            "choiceMyLanguage": "выбор моего языка",
            "HMethod": "Метод H революционизирует изучение языков, позволяя вам быстро прогрессировать",
            "listenOnly": "Слушать, не глядя на текст",
            "listenAndRead": "Слушать, читая текст",
            "read": "Читать текст вслух",
            "understand": "Постараться понять, если необходимо (с переводом)",

            "conversationPlaceholder": "Примеры: Турист и таксист в Майами, Собеседование ...",
            "storyPlaceholder": "Примеры: Путешествие в Амазонку, Человечество борется за выживание, Посещение памятника ...",

            "english":"Английский",
            "spanish":"Испанский",
            "arabic":"Арабский",
            "french":"Французский",
            "german":"Немецкий",
            "chinese":"Китайский",
            "japanese":"Японский",
            "hindi":"Хинди",
            "turkish":"Турецкий",
            "korean":"Корейский",
            "italian":"Итальянский",
            "dutch":"Голландский",
            "russian":"Русский",
            "indonesian":"Индонезийский",


        }
    },
    id: {
        translation: {
            "playAll": "Putar Semua",
            "conversationIn": "Percakapan di",
            "conversation": "Percakapan",

            "send": "Kirim",
            "comingSoon": "Segera hadir",

            "conversations": "Percakapan Saya",

            "story": "Cerita",
            "storyIn": "Cerita di",
            "myStories": "Cerita saya",
            "pauseAll": "Jeda",
            "speed": "Kecepatan",
            "speed_very_slow": "Sangat lambat",
            "speed_slow": "Lambat",
            "speed_normal": "Normal",
            "speed_fast": "Cepat",
            "speed_very_fast": "Sangat cepat",
            "level": "Tingkat",
            "level_easy": "Mudah",
            "level_intermediate": "Menengah",
            "level_advanced": "Lanjutan",
            "noDownloadsAvailable": "Tidak ada unduhan yang tersedia",
            "start": "Klik di sini untuk memulai",
            "myAccount": "Akun Saya",
            "signOut": "Keluar",
            "signInWithGoogle": "Masuk",
            "title": "Judul:",

            "menuLabel": "Setelah masuk, menu <0></0> sangat sederhana",
            "allowGenerateConversation": "mengizinkan untuk menghasilkan percakapan",
            "allowGenerateStory": "mengizinkan untuk menghasilkan cerita",
            "displayMyConversations": "tampilkan percakapan yang sudah saya buat",
            "displayMyStories": "tampilkan cerita yang sudah saya buat",
            "choiceMyLanguage": "pilihan bahasa saya",
            "HMethod": "Metode H merevolusi pembelajaran bahasa dengan membantu Anda berkembang dengan cepat",
            "listenOnly": "Dengarkan tanpa melihat teks",
            "listenAndRead": "Dengarkan sambil membaca teks",
            "read": "Baca teks dengan suara keras",
            "understand": "Cobalah untuk memahami, jika perlu (dengan terjemahan)",

            "conversationPlaceholder": "Contoh: Turis dan sopir taksi di Miami, Wawancara kerja ...",
            "storyPlaceholder": "Contoh: Perjalanan ke Amazon, Umat manusia berjuang untuk bertahan hidup, Kunjungan ke monumen ...",

            "english":"Inggris",
            "spanish":"Spanyol",
            "arabic":"Arab",
            "french":"Perancis",
            "german":"Jerman",
            "chinese":"Cina",
            "japanese":"Jepang",
            "hindi":"Hindi",
            "turkish":"Turki",
            "korean":"Korea",
            "italian":"Italia",
            "dutch":"Belanda",
            "russian":"Rusia",
            "indonesian":"Indonesia",


        }
    }
};

let defaultLang = localStorage.getItem('language');

if (!defaultLang) {
    defaultLang = navigator.language.slice(0, 2) || 'en';
    localStorage.setItem('language', defaultLang);
}

i18n
    .use(Backend)
    .use(initReactI18next) // passe l'instance de i18next à react-i18next.
    .init({
        resources,
        lng: defaultLang,
        fallbackLng: 'en',
        debug: true,
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        interpolation: {
            escapeValue: false // react déjà sécurisé.
        },
        ns: ['translation'],
        defaultNS: 'translation',
    });

export default i18n;
